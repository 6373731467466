import React, { useContext, useEffect, useState } from "react";
import { errorResponse } from "../../Helpers/response.js";
import Http from "../../security/Http";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Link, useNavigate } from "react-router-dom";

import Logo from "../../../assets/images/ic_affiliatesCover.webp";

import { Helmet, HelmetProvider } from "react-helmet-async";
import eventEmitter from "../../Events/EventEmitter.js";
import { toast } from "react-toastify";
import { LOGIN_PARTNER } from "../../../ApiRoutes.js";
import UserProfileContext from "../../../data/UserProfileContext.js";

const Login = () => {
  const navigate = useNavigate();
  const { profile, setProfile } = useContext(UserProfileContext);
  const [showPassword, setShowPassword] = useState(false);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    const customerToken = localStorage.getItem("accessToken");
    if (customerToken && customerToken !== "") {
      navigate("/home");
    }
  }, []);

  const onSubmit = async () => {
    if (username.length <= 0) {
      toast.error("Please enter a valid email address");
      return;
    }

    if (password.length <= 0) {
      toast.error("Please enter a valid password");
      return;
    }

    const postBody = {
      email: username,
      password: password,
    };

    Http.callApi(["post", LOGIN_PARTNER], postBody)
      .then(async (response) => {
        console.log("Login response", response.data);

        if (response.data.success == false) {
          toast.error(response.data.message);
          return;
        } else {
          await localStorage.setItem(
            "accessToken",
            response.data.data.access_token
          );

          eventEmitter.emit("updateUser");

          if (response.data.data.profile) {
            setProfile(response.data.data.profile);
            toast.success(
              `Welcome back ${
                response?.data?.data?.profile?.userId?.first_name ?? ""
              }`
            );
            navigate("/home");
          } else {
            toast.success(`Let's setup your Business Profile!`);
            navigate("/onboarding");
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          errorResponse(error);
        }
      });
  };

  return (
    <>
      <HelmetProvider>
        <div className="application">
          <Helmet>
            <meta charSet="utf-8" />
            <title>{`Business Affiliates | Control Panel | Opal Empire & Jewellery - A Cut Above The Rest | Buy & Sell Opal & Precious Metals Worldwide`}</title>
            <link
              rel="canonical"
              href={`https://affiliates.opalempire.com.au/`}
            />
            <meta
              property="og:title"
              content={`Business Affiliates | Control Panel | Opal Empire & Jewellery - A Cut Above The Rest | Buy & Sell Opal & Precious Metals Worldwide`}
            />
            <meta
              property="og:url"
              content={`https://affiliates.opalempire.com.au/`}
            />
            <meta
              property="og:image"
              content={
                "https://affiliates.opalempire.com.au/ic_affiliates_cover.webp"
              }
            />
          </Helmet>
        </div>
      </HelmetProvider>

      <div
        className="flex flex-col overflow-x-hidden items-center justify-center min-h-svh overflow-hidden w-screen px-4"
        style={{
          backgroundColor: "#0D0E11",
        }}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
          <div className="flex justify-center items-center">
            <Link to={"/"}>
              <img
                src={Logo}
                className="h-[100%] md:h-[5%] w-full md:w-full object-contain"
                alt="Opal Empire"
              />
            </Link>
          </div>

          <div className="flex justify-center items-center">
            <div className="flex flex-col bg-white rounded-2xl px-6 md:px-10 md:py-10 pt-8 pb-6 w-full max-w-lg">
              <h4 className="text-2xl md:text-3xl font-semibold">
                Opal Empire Business Affiliates
              </h4>
              <p className="font-normal mt-1 text-sm md:text-lg">
                Login to your Opal Empire Business Affiliate Account
              </p>

              <div className="flex flex-row justify-center items-center mt-3 mb-4">
                <div className="w-[30%] md:w-2/6 h-[1px] bg-lightGrey" />
                <p className="text-sm text-lightGrey font-normal mx-4">
                  Login via email
                </p>
                <div className="w-[30%] md:w-2/6 h-[1px] bg-lightGrey" />
              </div>

              <div className="flex flex-col">
                <div className="mt-1 md:mt-2 border border-grey px-2 md:px-4 py-2 md:py-4 rounded-lg">
                  <input
                    type={"text"}
                    id="email"
                    onChange={(val) => setUsername(val.target.value)}
                    className="w-full text-md md:text-base"
                    placeholder="Email address"
                  />
                </div>

                <div className="mt-3 md:mt-2 border flex flex-row border-grey px-2 md:px-4 py-2 md:py-4 rounded-lg">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    onChange={(val) => setPassword(val.target.value)}
                    className="w-full text-md md:text-base"
                    placeholder="Password"
                  />
                  <div
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                    className="cursor-pointer"
                  >
                    <VisibilityOutlinedIcon
                      style={{ color: showPassword ? "#000" : "#a6a9b3" }}
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-row my-3">
                <div className="flex flex-row justify-center items-center">
                  <input className="w-4 h-4" type="checkbox" />
                  <p className="ml-3">Remember Me</p>
                </div>
              </div>

              <div
                onClick={() => {
                  onSubmit();
                }}
                className="cursor-pointer w-full bg-opalBlue py-3 justify-center items-center rounded-xl mb-6 mt-3"
              >
                <p className="text-white font-medium text-md md:text-lg text-center">
                  Login
                </p>
              </div>
              <p
                onClick={() => navigate("/register")}
                className="text-lg font-medium text-center text-opalBlue tracking-wide cursor-pointer hover:underline"
              >
                Become an Opal Empire Business Affiliate now!
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
