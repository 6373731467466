import axios from "axios";
import { configHeaderAxios } from "../Helpers/response";
import { BASE_URL } from "../../ApiRoutes";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import UserProfileContext from "../../data/UserProfileContext";
import { toast } from "react-toastify";

function AxiosMiddleware(method, url, data) {
  var options = configHeaderAxios();
  switch (method) {
    case "get":
      return axios.get(url, { ...options, params: data });
    case "post":
      return axios.post(url, data, options);
    case "head":
      return axios.head(url, data, options);
    case "patch":
      return axios.patch(url, data, options);
    case "put":
      return axios.put(url, data, options);
    case "delete":
      return axios.delete(url, { ...options, params: data });
    default:
      break;
  }
}
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.common["X-CSRF-TOKEN"] = "token.content";

export function callApi(url, data = []) {
  var options = configHeaderAxios();
  return AxiosMiddleware(
    url[0],
    // "http://localhost:3004/api/v2/" + url[1],
    BASE_URL + url[1],
    data,
    options
  );
}

export function setAuthToken(token, navigate) {
  axios.defaults.headers.common["Authorization"] = token;

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        localStorage.setItem("accessToken", "");
        toast.error(
          "You've been logged out to protect your account. Please login again"
        );
        navigate("/");
      }
    }
  );
}
export function setVerifyToken(token) {
  axios.defaults.headers.common["VerifyToken"] = `${token}`;
}
export function setLocalizationLanguage(language) {
  axios.defaults.headers.common["X-localization"] = `${language}`;
}
